import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Gallery from "../components/gallery.js";
;

const PageTemplate = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
      />
      <main>
        <section>
          <div className="contact">
              {data.allWordpressPage.edges.map((post, index) => (
                <div className="content contact-wrapper">
                    <div className="logoName">
                    <h3>
                      <span>konzept</span>
                      <span className="studio">studio</span>
                      <span className="plan">plan</span>
                      <span>werk</span>
                    </h3>
                  </div>
                  <div className="content-text"
                    dangerouslySetInnerHTML={{ __html: post.node.acf.content }}
                  />
                  <Gallery images={post.node.acf.gallery} />
                  <div className="adresse"
                    dangerouslySetInnerHTML={{ __html: post.node.acf.adresse}}>
                  </div>
                  <div className="phone"
                  dangerouslySetInnerHTML={{ __html: post.node.acf.phone}}>
                </div>
                </div>
              ))}
       
          </div>
        </section>
      </main>
    </Layout>
  );
};
export default PageTemplate;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "kontakt" }) {
      excerpt
      type
    }
    allWordpressPage(filter: { slug: { eq: "kontakt" } }) {
      edges {
        node {
          title
          content
          acf {
            content
            adresse
            phone
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
